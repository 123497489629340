import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'constants/FormSchema';
import { requestResetPassword } from 'requests/auth/password';
import useAlert from 'utils/Alert';

function EmailChecker() {
  const {
    AuthStore: { isLoading, errors: reqErrors, checkEmail, setResetingPassword }
  } = useStore();

  const Alert = useAlert();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<{ email: string }>({
    resolver: yupResolver(emailValidation)
  });

  const onSubmit = ({ email }: { email: string }, ev: any) => {
    if (ev.nativeEvent.submitter.matches('#check-email')) {
      checkEmail(email);
    } else if (ev.nativeEvent.submitter.matches('#reset-password')) {
      setResetingPassword(true);
      requestResetPassword(email)
        .then(() => {
          Alert.success('Password reset link sent. please check your email.');
        })
        .catch((err) => {
          Alert.error(err.response?.data?.message || err.message);
        })
        .finally(() => setResetingPassword(false));
    }
  };

  return (
    <>
      <MDTypography color="error" textAlign="center" height="20px" fontSize="11px" mb="1.6rem">
        {reqErrors.checkEmail}
      </MDTypography>
      <MDBox component="form" role="form" id="check-email-form" onSubmit={handleSubmit(onSubmit)}>
        <MDTypography variant="body2" color="error" textAlign="center" mb="1rem" fontSize="11px">
          {errors.email?.message || ''}
        </MDTypography>

        <MDBox mb={4}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            value={watch('email')}
            data-testid="email-input"
            {...register('email')}
            error={!!errors.email}
          />
          <MDTypography fontSize="12px">Please input your email</MDTypography>
        </MDBox>

        <MDButton variant="gradient" color="primary" fullWidth type="submit" id="check-email">
          {isLoading.checkEmail ? 'Loading...' : 'Next'}
        </MDButton>
      </MDBox>
    </>
  );
}

export default observer(EmailChecker);
