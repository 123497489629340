import { useEffect, useState } from 'react';

const useCountDown = (countNumber: number) => {
  const [counter, setCounter] = useState(countNumber);

  const minutesLeft = Math.floor(counter / 60);
  const secondsLeft = counter - minutesLeft * 60;
  const timeToCodeResend = `${minutesLeft}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
  const resendCodeActive = timeToCodeResend === '0:00';

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((cnt) => (cnt > 0 ? cnt - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return { timeToCodeResend, resendCodeActive, setCounter };
};

export default useCountDown;
