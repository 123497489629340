import { IconButton, InputAdornment, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import MDInput from 'components/MDInput';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EAuthStage } from 'store/AuthStore';
import RSAEncrypt from 'utils/RSA';
import { LoginSchema } from 'constants/FormSchema';
import { Page } from 'constants/route';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useState } from 'react';

const PasswordChallenge = ({ email }: { email: string }) => {
  const route = useRouter();
  const {
    AuthStore: { isLoading, errors: reqErrors, login, setAuthStage, setCardSlideDir }
  } = useStore();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ password: string }>({
    resolver: yupResolver(LoginSchema)
  });

  const onSubmit = ({ password }: { password: string }) => {
    login(RSAEncrypt(JSON.stringify({ email, password })));
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography
        variant="h3"
        fontSize="lg"
        sx={{ i: { color: 'blue' } }}
        mt="1rem"
        textAlign="center"
      >
        Welcome&nbsp;
        <MDButton
          variant="outlined"
          title="Login as a different account"
          onClick={async () => {
            setCardSlideDir('left');
            await route.replace(Page.Auth.login, undefined, { shallow: true });
            setAuthStage(EAuthStage.CHECK_EMAIL);
          }}
        >
          <i>{email}</i>
        </MDButton>
      </Typography>
      <Typography sx={{ i: { color: 'success' }, mb: 2 }} textAlign="center">
        Please enter your password to continue to your dashboard
      </Typography>

      <Typography color="red" textAlign="center" height="10px" mb="2rem" fontSize="10px">
        {reqErrors.login}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MDInput
          size="medium"
          fullWidth
          sx={{
            mb: '1rem',
            borderColor: 'gray'
          }}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...register('password')}
        />
        <MDTypography fontSize="0.8rem">{errors.password && errors.password.message}</MDTypography>
        <MDButton fullWidth variant="gradient" type="submit" sx={{ mt: '10px' }} color="primary">
          {isLoading.login ? 'Loading...' : 'LOG IN'}
        </MDButton>
      </form>
    </>
  );
};

export default observer(PasswordChallenge);
