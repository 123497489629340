import { Typography, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store';

const InitAccount = () => {
  const {
    AuthStore: { signinEmail }
  } = useStore();
  return (
    <Stack spacing="0.9rem">
      <Typography variant="h3" fontSize="20px">
        Your account was migrated to a newer and more stable version of our app
      </Typography>
      <Typography fontSize="16px" sx={{ i: { color: 'blue' } }}>
        Please check your email <i>{signinEmail}</i> to create a new password
      </Typography>
    </Stack>
  );
};

export default observer(InitAccount);
