import { forwardRef } from 'react';

import { InputProps } from '@mui/material/Input';
import { TextFieldProps } from '@mui/material/TextField';
import MDInputRoot, { IMDInputProps } from './MDInputRoot';

const MDInput = forwardRef<any, Partial<IMDInputProps> & InputProps & TextFieldProps>(
  ({ error, success, disabled, ...rest }, ref) => (
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
  )
);

MDInput.displayName = 'MDInput';

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false
};

export default MDInput;
