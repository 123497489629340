import { Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import useCountDown from 'hooks/useCountDown';

const RESEND_DURATION = 60;

const ResendCounter = ({ onClick }: { onClick(): void }) => {
  const { resendCodeActive, setCounter, timeToCodeResend } = useCountDown(RESEND_DURATION);

  return (
    <MDBox fontSize="14px" pt="1rem">
      <MDBox
        alignItems="center"
        display="flex"
        sx={{
          direction: 'column'
        }}
      >
        <Typography fontSize="clamp(0.5rem, 1.2vw, 1.3rem)" mr="0.3rem">
          Did Not Get Code?
        </Typography>
        <MDButton
          color="success"
          variant="text"
          type="button"
          sx={{
            textAlign: 'center'
          }}
          disabled={!resendCodeActive}
          onClick={() => {
            setCounter(RESEND_DURATION);
            onClick();
          }}
        >
          {resendCodeActive ? 'Resend' : timeToCodeResend}
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default ResendCounter;
